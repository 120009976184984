<template>
    <div>
        <h2><router-link :to="{ name: 'rog' }">รายการรับ</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}รายการรับ {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.po"
                        label="รายการสั่งซื้อ"
                        placeholder="พิมพ์เพื่อค้นหารายการสั่งซื้อ (เว้นว่างหากไม่ต้องการอ้างอิง)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/procure/rog/poList'"
                        dense
                        return-object                        
                        item-text="orderNumber"
                        item-value="id"
                        :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                        hide-details="auto"
                        @change="selectPO"
                      ></delay-autocomplete>
                    </v-col>                    
                  </v-row>       
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>           
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.supplier"
                        label="ผู้จำหน่าย / ซัพพลายเออร์"
                        placeholder="พิมพ์เพื่อค้นหาซัพพลายเออร์ (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/procure/rog/supplierList'"
                        dense
                        return-object                        
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isSupplierChangable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        hide-details="auto"
                        @change="selectSupplier"
                      ></delay-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.taxno"
                        label="เลขประจำตัวผู้เสียภาษี"                    
                        no-data-text="ไม่พบข้อมูล"                        
                        dense                                                                                                                        
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.address"
                        label="ที่อยู่"
                        dense
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row> 
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse"
                        label="คลัง/สาขา ที่ซื้อเข้า"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/procure/rog/warehouseList'"
                        dense
                        return-object
                        ref="salechannelAutocomplete"                        
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isWarehouseChangeable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></delay-autocomplete>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <r-o-g-product-input
                      v-model="order"
                      :url="'/procure/rog'"
                      :warehouse_id="!!order.warehouse ? order.warehouseId : null"
                      :disabled="!order.isItemEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></r-o-g-product-input>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model.number="order.misc_cost"
                        label="ค่าใช้จ่ายอื่น ๆ ที่อยู่นอกระบบภาษี"                                            
                        dense                                                                                                                        
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.note"
                        label="หมายเหตุ"
                        dense
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid || order.orderItems.length == 0"
                :loading="sendingData"
                @click="submitForm(false)"
                v-if="$store.getters['app/GET_APP_MODE'] == 'create'"
                >
                รับของ
                </v-btn>

                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'rog' })"
                >
                กลับ
                </v-btn>          
                
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { ROGOrder } from '@/classes/order/ROGOrder'
import { ROGOrderItem } from '@/classes/order/ROGOrderItem'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import ROGProductInput from '@/components/order/ROGProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      menu: false,
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
      icons: {
        mdiCalendar,
      },
    }
  },
  async created() {
    const rog_id = this.$route.params.rog_id

    if (rog_id == 'create') {
      this.order = new ROGOrder({})

      if (this.$route.query.po) {
        try {
          const response = await asyncGet('/procure/po/' + this.$route.query.po)

          this.selectPO(response)
        } catch (error) {
          this.$root.showCommonDialog('มีปัญหา', error)
        }
      }
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'BuyOrder'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/procure/rog/' + rog_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.currentStatus = this.order.status
  },
  methods: {
    async submitForm() {
      const unmatchList = this.order.unmatchList

      if (!!unmatchList && unmatchList.length > 0) {
        const unmatchListHTML = unmatchList.join('<br>')

        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'รับของที่ไม่ตรงกับจำนวน ?',
          body:
            'รายการที่ไม่ตรงมีดังนี้' +
            '<br>' +
            '<br>' +
            unmatchListHTML +
            '<br>' +
            '<br>' +
            'คุณยังยืนยันที่จะรับหรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
        })

        if (!dialogResult) return
      }

      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
        }

        const rog_id = this.$route.params.rog_id
        if (rog_id == 'create') {
          const retResult = await asyncPostAsJson('/procure/rog', sendData)

          this.$router.push({
            name: 'rog',
          })
        } else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/procure/rog/' + rog_id, sendData)

          this.$router.push({ name: 'rog' })
        }
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new ROGOrder(orderDataFromServer)

      const { products } = orderDataFromServer

      const order = this.order

      products.forEach(element => {
        const orderItem = new ROGOrderItem({
          id: element.id,
          images: element.images,
          name: element.name,
          sku: element.sku,
          barCode: element.barCode,
          note: element.pivot.note,
          quantity: element.pivot.quantity,
          price: element.pivot.price,
          discount: element.pivot.discount,
          discount_type: element.pivot.discount_type,
          vat: element.pivot.vat,
          unit: element.unit,
        })

        order.addOrderItem(orderItem)
      })
    },
    selectPO(payload) {
      if (!payload) return (this.order = new ROGOrder({}))

      this.order = new ROGOrder({ ...payload, po: payload })

      const order = this.order

      const { products } = payload

      products.forEach(element => {
        const orderItem = new ROGOrderItem({
          id: element.id,
          images: element.images,
          name: element.name,
          sku: element.sku,
          barCode: element.barCode,
          note: element.pivot.note,
          quantity: 0,
          expectedQuantity: element.pivot.quantity,
          price: element.pivot.price,
          discount: element.pivot.discount,
          discount_type: element.pivot.discount_type,
          vat: element.pivot.vat,
          unit: element.unit,
        })

        order.addOrderItem(orderItem)
      })
    },
    selectSupplier({ taxno, address }) {
      this.order.taxno = taxno
      this.order.address = address
    },
  },
  components: {
    DelayCombobox,
    LightBox,
    ROGProductInput,
  },
}
</script>,